import { FileDisputeDrawer } from "./file_dispute_drawer"
import { Notes } from "./notes"
import { ShieldAlert } from "lucide-react"
import { DeductionResponse } from "@/api/deduction.tsx"
import { Link } from "wouter-preact"

export function Actions({ deduction }: { deduction: DeductionResponse }) {
  return (
    <div className="flex justify-between items-center gap-1">
      <Notes deduction_id={deduction.id} notes={deduction.notes} />
      {!deduction.dispute_id ? (
        <FileDisputeDrawer
          deduction={deduction}
          trigger={<ShieldAlert className="h-5 w-5 cursor-pointer text-red-600" />}
        />
      ) : (
        <Link to={`/deduction/${deduction.id}`}>
          {" "}
          <ShieldAlert className="h-5 w-5 text-green-500" />{" "}
        </Link>
      )}
    </div>
  )
}
