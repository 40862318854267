export enum TaskStatus {
  Pending = "pending",
  Completed = "completed",
  Archived = "archived",
}

export enum TaskType {
  FileDispute = "file-dispute",
  PullBackup = "pull-backup",
  Followup = "followup",
  Validate = "validate",
  MapAccountingCode = "map-accounting-code",
}

export interface TaskInfo {
  task_type: TaskType;
  task_status: TaskStatus;
  task_user_id: string; // Assuming Sid<User> is a string
  task_created_at: string; // Assuming DateTime is a string
  task_assigner_email: string;
  task_id: string; // Assuming Sid<Task> is a string
  task_user_email: string;
}