import { useState } from "preact/compat"
import { api_fetch } from "../api/client.tsx"
import { useAsyncEffect } from "../utils/util.tsx"
import { Deduction, DeductionResponse } from "@/api/deduction.tsx"
import { StatusFlag } from "../dashboard/deductions/status_state.tsx"
import { BreadcrumbNav, DataTableState } from "@/dashboard/common.tsx"
import { GenericFilter } from "@/dashboard/component/filter.tsx"
import { DataTable } from "@/dashboard/deductions/table/data_table.tsx"
import { columns } from "./accounting_columns.tsx"
import { DAY_COUNTS, dayCountToString, DISTRIBUTORS } from "@/dashboard/deductions/index.tsx"
import { Card } from "@/components/ui/card.tsx"
import { CardBody } from "@/dashboard/deductions/summary_cards.tsx"
import { Button } from "@/components/ui/button.tsx"

interface AccountingStats {
  codedCount: number
  uncodedCount: number
}

// custom filter values
const CODED = "coded"
const UNCODED = "uncoded"
const CURRENT_MONTH = "currentMonth"
const PREVIOUS_MONTH = "previousMonth"

export function Accounting() {
  const statusFlag = StatusFlag.use(sf => sf!)

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any[]>([])
  const [stats, setStats] = useState<AccountingStats>()
  const [customFilter, setCustomFilter] = useState<string>("all")
  // state variable for custom filters that we apply directly to the data (coded -> reason_code exists, uncoded -> reason_code is null, current month, previous month)
  const tableState = DataTableState.use()

  const {
    days: selectedDay = DAY_COUNTS[8],
    distributor: selectedDistributor = DISTRIBUTORS[0],
  } = tableState

  function handleDayCountChange(days: number) {
    DataTableState.set({ ...tableState, days })
  }

  function handleDistributorChange(distributor: string) {
    DataTableState.set({ ...tableState, distributor })
  }

  function handleSetCustomFilter(filter: string) {
    if (filter === customFilter) {
      setCustomFilter("all")
    } else {
      setCustomFilter(filter)
    }
  }

  useAsyncEffect(async () => {
    let distributor = selectedDistributor === "All Distributors" ? "" : selectedDistributor
    const res = await api_fetch<Deduction[]>("/deduction", {
      params: {
        days: selectedDay,
        distributor,
        query_type: "accounting",
      },
    })

    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }

    setLoading(false)

    const data = res.value.data.map(d => {
      return Object.fromEntries(
        Object.entries(d).map(([k, v]) => [k, v ?? ""]),
      ) as DeductionResponse
    })

    let filteredData = data
    if (customFilter === CODED) {
      filteredData = data.filter(d => d.reason_code)
    } else if (customFilter === UNCODED) {
      filteredData = data.filter(d => !d.reason_code)
    } else if (customFilter === CURRENT_MONTH) {
      const currentMonth = new Date().getMonth()
      filteredData = data.filter(d => new Date(d.invoice_date).getMonth() === currentMonth)
    } else if (customFilter === PREVIOUS_MONTH) {
      const previousMonth = new Date().getMonth() - 1
      filteredData = data.filter(d => new Date(d.invoice_date).getMonth() === previousMonth)
    }

    const FILTERS_THAT_UPDATE_CODED_COUNT = [CURRENT_MONTH, PREVIOUS_MONTH]
    setData(filteredData)
    setStats(
      FILTERS_THAT_UPDATE_CODED_COUNT.includes(customFilter) ? (
        filteredData.reduce(
          (acc, deduction) => {
            if (deduction.reason_code) {
              acc.codedCount += 1
            } else {
              acc.uncodedCount += 1
            }
            return acc
          },
          { codedCount: 0, uncodedCount: 0 } as AccountingStats,
        )
      ) : data.reduce(
        (acc, deduction) => {
          if (deduction.reason_code) {
            acc.codedCount += 1
          } else {
            acc.uncodedCount += 1
          }
          return acc
        },
        { codedCount: 0, uncodedCount: 0 } as AccountingStats,
      ),
    )
  }, [selectedDay, statusFlag, selectedDistributor, customFilter])

  return (
    <div>
      <BreadcrumbNav
        items={[
          { label: "Accounting", href: "/accounting" },
        ]}
      />
      <div className="flex gap-4 -mt-10 justify-end">
        <GenericFilter
          items={DISTRIBUTORS}
          selectedItem={selectedDistributor}
          handleOnChange={handleDistributorChange}
          itemToString={item => item}
          className="w-36"
        />
        <GenericFilter
          items={DAY_COUNTS}
          selectedItem={selectedDay}
          handleOnChange={handleDayCountChange}
          itemToString={dayCountToString}
          className="w-40 max-w-sm"
        />
        <div className="flex gap-4 flex-col w-32 justify-end">
          <Button
            variant={customFilter === CURRENT_MONTH ? "tertiary" : "outline"}
            onClick={() => handleSetCustomFilter(CURRENT_MONTH)}>
            Current Month
          </Button>
          <Button
            variant={customFilter === PREVIOUS_MONTH ? "tertiary" : "outline"}
            onClick={() => handleSetCustomFilter(PREVIOUS_MONTH)}>
            Previous Month
          </Button>
        </div>
      </div>

      <ProgressCards
        stats={stats}
        customFilter={customFilter}
        handleSetCustomFilter={handleSetCustomFilter}
      />
      {/* TODO(joey): add button to "apply rules"
        {/* TODO(joey): polish UI, upload all relevant reason codes for bellway / super (and eventually make sure to only show reason codes that're relevant to the selected distributor? seems like there are amazon-specific codes etc.) */}
      <DataTable loading={loading} columns={columns} data={data} />
    </div>
  )
}

const ProgressCards = ({
                         stats,
                         customFilter,
                         handleSetCustomFilter,
                       }: {
  stats?: AccountingStats
  customFilter: string
  handleSetCustomFilter: (filter: string) => void
}) =>
  stats ? (
    <div class="flex flex-wrap gap-4">
      <div class="flex flex-wrap w-1/2 gap-4">
        <Card className={`h-fit w-1/5 ${customFilter === CODED && "border-plue-400"}`}>
          <CardBody
            value={stats.codedCount}
            title="Coded"
            color="green-700"
            onClick={() => handleSetCustomFilter(CODED)}
          />
        </Card>
        <Card className={`h-fit w-1/5 ${customFilter === UNCODED && "border-plue-400"}`}>
          <CardBody
            value={stats.uncodedCount}
            title="Uncoded"
            color="red-800"
            onClick={() => handleSetCustomFilter(UNCODED)}
          />
        </Card>
      </div>
    </div>
  ) : null
