import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { useEffect, useMemo, useState } from "preact/compat"
import { Button } from "@/components/ui/button"

import { Skeleton } from "@/components/ui/skeleton"
import { ChevronDown, Columns2 } from "lucide-react"
import { useSearchParams } from "src/utils/util.tsx"
import { PageControls } from "../table/page_controls"
import { Backup } from "@/api/backup.tsx"

interface DataTableProps<TData, TValue> {
  loading: boolean
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // ok if the first row in the table has a value that's null, that column won't be filtered
  // https://github.com/TanStack/table/issues/4919
  // thought this was fixed by returning '' instead of null but decided to just fix it by passing empty strings to the table.
  const itemValue = row.getValue(columnId)
  if (!itemValue) return false

  const itemRank = rankItem(itemValue, value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

// todo(joey): allow filtering for not values to support clicking to show Misc items

export function BackupTable<TValue>({
                                      loading,
                                      columns,
                                      data,
                                    }: DataTableProps<Backup, TValue>) {
  const params = useSearchParams<{ search?: string }>()

  const [rowSelection, setRowSelection] = useState({})
  const [sorting, setSorting] = useState<SortingState>([{ id: "invoice_date", desc: true }])
  const [globalFilter, setGlobalFilter] = useState(params.search ?? "")
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })

  useEffect(() => {
    const visibleColumns: VisibilityState = {}
    columns.forEach((column: any) => {
      visibleColumns[column.accessorKey as string] = data.some(row => {
        const value = row[column.accessorKey as keyof Backup]
        return !!value
      })
    })
    setColumnVisibility(visibleColumns)
    // TODO(joey): how to handle when the data is in different structure? does this happen in practice?
  }, [data, columns])

  const table = useMemo(() => {
    return useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      globalFilterFn: fuzzyFilter,
      onColumnVisibilityChange: setColumnVisibility,
      onGlobalFilterChange: setGlobalFilter,
      onRowSelectionChange: setRowSelection,
      onSortingChange: setSorting,
      onPaginationChange: setPagination,
      state: {
        columnVisibility,
        rowSelection,
        sorting,
        globalFilter,
        pagination,
      },
    })
  }, [data, columns, columnVisibility, globalFilter, rowSelection, sorting, pagination])

  // Handle data change to keep pagination state
  useEffect(() => {
    if (data.length < pagination.pageIndex * pagination.pageSize) {
      setPagination(prev => ({ ...prev, pageIndex: 0 }))
    }
  }, [data, pagination.pageIndex, pagination.pageSize])

  return (
    <>
      {table.getRowModel().rows?.length > 0 ? (
        <div className="flex items-center justify-between py-4 pt-8">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto text-slate-500">
                <Columns2 className="h-4" />
                Columns
                <ChevronDown className="h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter(column => column.getCanHide())
                .map(column => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value: any) => column.toggleVisibility(!!value)}>
                      {column.id.replaceAll("_", " ")}
                    </DropdownMenuCheckboxItem>
                  )
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ) : (
        <div class="mt-4" />
      )}
      <Table>
        <TableHeader className="bg-slate-50">
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead key={header.id} className="first:rounded-tl-md last:rounded-tr-md">
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        {loading ? (
          <TableBody>
            <TableRow>
              {Array.from({ length: columns.length }).map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton className="w-[100px] h-[20px] rounded-full bg-slate-500" />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell
                      className="hover:cursor-pointer"
                      // onClick={() => handleRowClick(row, cell.id)}
                      key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No backup parsed.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      <div class="flex items-center justify-between p-2">
        <PageControls table={table} />
      </div>
    </>
  )
}
