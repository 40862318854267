import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"

import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { useToast } from "@/components/ui/use-toast"
import { useState } from "preact/hooks"
import { StatusFlag } from "../../status_state"
import { classes } from "@/utils/util"
import React from "preact/compat"
import { File } from "lucide-react"
import { file_dispute } from "@/api/dispute.tsx"
import { Deduction } from "@/api/deduction.tsx"

const DEFAULT_DISPUTE_MESSAGE = "Hi, please see attached documents showing that this is an invalid charge. Thank you in advance and please let me know if you have any questions."

interface FileDisputeDrawerProps {
  deduction: Deduction
  trigger: JSX.Element
}

export function FileDisputeDrawer({ deduction, trigger }: FileDisputeDrawerProps) {
  let [selectedFiles, setSelectedFiles] = useState<any[]>([])
  let [uploading, setUploading] = useState(false)
  let [disputeMessage, setDisputeMessage] = useState(DEFAULT_DISPUTE_MESSAGE)
  let [disputeAmount, setDisputeAmount] = useState<number>(deduction.invoice_amount)
  const { toast } = useToast()

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    // @ts-ignore
    const updatedFiles = [...selectedFiles, ...event!.target!.files]
    setSelectedFiles(updatedFiles)
  }

  function handleDisputeAmountChange(event: React.ChangeEvent<HTMLInputElement>) {
    // @ts-ignore
    setDisputeAmount(event.target!.value)
  }

  async function handleFileDispute() {
    setUploading(true)

    let res = await file_dispute({
      deduction,
      message: disputeMessage,
      dispute_amount: disputeAmount,
      files: selectedFiles,
    })

    if (!res.ok) {
      toast({
        title: "Error",
        description: "Failed to file dispute. Please try again.",
        variant: "destructive",
      })
      console.error("Failed to file dispute")
    } else {
      toast({
        title: "Dispute filed",
        description: "Successfully filed dispute. Messages will be synced in a few minutes.",
      })
      console.log("Dispute filed successfully")
    }
    StatusFlag.set(flag => !flag) // Re-render
    setUploading(false)
  }

  let invoice_number = deduction.invoice_number
  let source = deduction.source
  let backup_s3_uri = deduction.backup_s3_uri
  return (
    <Drawer>
      <DrawerTrigger>
        {trigger}
      </DrawerTrigger>

      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>File Dispute</DrawerTitle>
          <DrawerDescription>
            Review, edit, and upload relevant files then hit submit to file dispute.
          </DrawerDescription>

          <DrawerTitle className="text-md pt-2">Title</DrawerTitle>
          <DrawerDescription>
            {`Supplier Dispute Form - Invoice #: ${invoice_number}`}
          </DrawerDescription>

          <DrawerTitle className="text-md pt-2">Message</DrawerTitle>
          {/* @ts-ignore */}
          <Textarea value={disputeMessage} onChange={(e: any) => setDisputeMessage(e.target.value)}
                    className="border border-plue-100 hover:border-plue-500" />

          {source === "unfi" && (<><DrawerTitle>Dispute amount</DrawerTitle> <input
            type="number"
            step="0.01"
            value={disputeAmount}
            onChange={handleDisputeAmountChange}
            className="border border-plue-100 hover:border-plue-500 p-1 rounded w-36 text-right"
          /></>)}

          <DrawerTitle className="text-md pt-2">Attachments</DrawerTitle>
          <div className="flex flex-col">
            {backup_s3_uri && (
              <div className="flex items-center">
                <File className="w-4 h-4 mr-1" />
                <DrawerDescription>Original backup</DrawerDescription>
              </div>
            )}
            <div className="preview">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="flex items-center mt-2">
                    <File className="w-4 h-4 mr-1" />
                    <DrawerDescription>{file.name}</DrawerDescription>
                  </div>
                ))}
              </div>

            <form method="post" encType="multipart/form-data">
              <div class={classes(backup_s3_uri ? "mt-4" : "", "mb-2")}>
                <label for="image_uploads" class="btn btn-secondary">
                  Select files
                </label>
                <input
                  type="file"
                  style="opacity: 0"
                  id="image_uploads"
                  name="image_uploads"
                  multiple
                  onChange={handleFileChange}
                  className="mt-2"
                />
              </div>
              <div className="preview" />
            </form>
          </div>
        </DrawerHeader>

        <DrawerFooter className="flex flex-row items-start">
          <DrawerClose>
            <Button onClick={handleFileDispute} disabled={uploading} variant="tertiary">
              {uploading ? "Uploading..." : "Submit"}
            </Button>
          </DrawerClose>
          <DrawerClose>
            <Button variant="outline">Cancel</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
