import { actionsColumn, createDateColumn, DeductionTableColumn, distributorColumn, filesColumn, invoiceAmountColumn, invoiceNumberColumn, selectColumn, statusColumn, taskColumn } from "../deductions/table/columns"

export const columns: DeductionTableColumn[] = [
  selectColumn,
  createDateColumn("invoice_date", "Invoice Date"),
  invoiceNumberColumn,
  invoiceAmountColumn,
  distributorColumn,
  filesColumn,
  createDateColumn("check_date", "Check Date"),
  statusColumn,
  actionsColumn,
  taskColumn,
//   todo: difference between check date and invoice date in days or is that not the payment date??
]