import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Icon, Title } from "@tremor/react"
import { Link } from "wouter-preact"

import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator } from "@/components/ui/breadcrumb";

import { entity, persistence } from "simpler-state"
import { DeductionResponse } from "@/api/deduction";

interface DataTableParams {
  search?: string
  days?: number
  distributor?: string
  rowSelection?: DeductionResponse | {} 
  sorting?: { id: string, desc: boolean }[]
  columnVisibility?: { [key: string]: boolean }
  pagination?: { pageIndex: number, pageSize: number }
}

export const DataTableState = entity<DataTableParams>({}, [persistence("deduction_table_state")])

interface BreadcrumbNavProps {
  items: { href: string; label: string }[];
}

export const BreadcrumbNav = ({ items }: BreadcrumbNavProps) => {
  return (
    <div className="-mx-6 flex flex-col sm:gap-4 py-2 pb-4 max-w-fit">
      <header
        className="sticky top-0 z-30 flex h-4 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
        <Breadcrumb className="hidden md:flex">
          <BreadcrumbList>
            {items.map((item, index) => (
              <>
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <Link href={item.href}>{item.label}</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                {index < items.length - 1 && <BreadcrumbSeparator />}
              </>
            ))}
          </BreadcrumbList>
        </Breadcrumb>
      </header>
    </div>
  );
};

const FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const DATE_FORMATTER = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
})

// whole dollar amounts, no cents
export const currencyFormatter = (number: number) => FORMATTER.format(Math.round(number)).toString().slice(0, -3)

export const exactCurrencyFormatter = (number: number) => FORMATTER.format(number)

export const dateFormatter = function(date: string | undefined, formatter = DATE_FORMATTER): string {
  if (!date) {
    return ""
  }
  try {
    return formatter.format(new Date(date))
  } catch (e) {
    console.warn(`Could not format data as date: ${date}`)
    return date
  }
}

export type Cell = string | number | boolean

export interface QueryData {
  headers: string[]
  rows: Cell[][]
}

export interface TitleAreaProps {
  title: string
  link?: string
  titleTooltip?: string
}

export function TitleArea({ title, link, titleTooltip }: TitleAreaProps) {
  return <div class="flex">
    <Title>
      {link ? <Link to={link}>{title}</Link> : title}
    </Title>
    {titleTooltip && <Icon
      color="gray"
      icon={InformationCircleIcon}
      variant="simple"
      tooltip={titleTooltip}
    />}
  </div>
}

export interface Data<T extends any[]> {
  headers: string[]
  rows: T[]
}