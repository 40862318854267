import { BarList } from "@tremor/react"
import { useState } from "preact/compat"
import { api_fetch } from "../../api/client.tsx"
import { useAsyncEffect } from "../../utils/util.tsx"
import { currencyFormatter, DataTableState, QueryData } from "../common.tsx"
import { LoadingSpinner } from "./summary_cards.tsx"
import { CATEGORY_COLORS } from "./table/columns.tsx"
import { StatusFlag } from "./status_state.tsx"

interface DeductionCategoryData {
  name: string
  value: number
}

export function CategoryBarChart({
  selectedDay,
  selectedDistributor,
}: {
  selectedDay: number
  selectedDistributor: string
}) {
  const [data, setData] = useState<DeductionCategoryData[]>([])
  const [loading, setLoading] = useState(true)
  const tableState = DataTableState.use()
  const statusFlag = StatusFlag.use(sf => sf!)

  useAsyncEffect(async () => {
    let params = {
      days: selectedDay,
      distributor: selectedDistributor === "All Distributors" ? "" : selectedDistributor,
      query_name: "category",
    }
    let res = await api_fetch<QueryData>("/deductions/query", { params })
    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }
    setLoading(false)

    let headers = res.value.data.headers
    let headerIndex = Object.fromEntries(headers.map((header, index) => [header, index]))

    let rows = res.value.data.rows.filter(row => row[headerIndex.category_name] !== "Total")

    rows.sort(
      (a, b) =>
        (a[headerIndex.total_invoice_amount] as number) -
        (b[headerIndex.total_invoice_amount] as number)
    )

    setData(
      rows.map(row => ({
        name: row[headerIndex.category_name] as string,
        value: Math.abs(row[headerIndex.total_invoice_amount] as number) as number,
        href: `?search=${row[headerIndex.category_name] as string}`,
        target: "",
        color: CATEGORY_COLORS[row[headerIndex.category_name] as keyof typeof CATEGORY_COLORS],
      }))
    )
  }, [selectedDay, selectedDistributor, statusFlag])

  if (loading || !data) {
    return <LoadingSpinner color="blue" />
  }

  return (
    <div class="">
      <BarList
        data={data}
        className="max-w-2xl"
        valueFormatter={currencyFormatter}
        onClick={(e: any) => {
          e.preventDefault()
          // hacky but better than mixing params / state
          let href = e.target?.href as string | undefined
          if (href) {
            let search = href?.includes("=") ? decodeURIComponent(href.split("=")[1]) : ""
            DataTableState.set({ ...tableState, search })
          }
        }}
      />
    </div>
  )
}
