import { useEffect, useState } from "preact/compat"
import { formatDistributor, useAsyncEffect } from "@/utils/util.tsx"
import { DeductionResponse, DisputeMessage, EmailMessage } from "@/api/deduction.tsx"

import { Uuid } from "src/utils/type.tsx"
import { Card } from "@/components/ui/card.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { Updates } from "./updates.tsx"
import { Conversations } from "./conversations.tsx"
import { LoadingSpinner } from "../summary_cards.tsx"
import { Badge, BadgeVariantOptionType } from "@/components/ui/badge.tsx"
import { capitalCase } from "change-case"
import { STATUS_ICONS } from "../table/columns.tsx"
import { DeductionFile, Files } from "./files.tsx"
import { StatusFlag } from "../status_state.tsx"
import { BackupTable } from "./backup_table.tsx"
import { backup_columns } from "./backup_columns.tsx"
import { api_fetch } from "src/api/client.tsx"
import { Button } from "@/components/ui/button.tsx"
import { ShieldAlert } from "lucide-react"
import { FileDisputeDrawer } from "../table/actions/file_dispute_drawer.tsx"
import { BreadcrumbNav } from "@/dashboard/common.tsx"
import { Backup } from "@/api/backup.tsx"

function getFiles(
  deduction: DeductionResponse,
  emails: EmailMessage[],
  messages: DisputeMessage[],
): DeductionFile[] {
  const files: DeductionFile[] = []
  for (const email of emails) {
    for (const attachment of email.attachments) {
      if (
        attachment.s3_uri == deduction.backup_s3_uri ||
        attachment.s3_uri == deduction.check_s3_uri
      ) {
        continue
      }
      files.push({
        display_name: attachment.s3_uri.split("/").pop() || "Email Attachment",
        date: email.date,
        s3_uri: attachment.s3_uri,
        deduction_id: deduction.id,
      })
    }
  }
  for (const message of messages) {
    for (const s3_uri of message.attachment_s3_paths ?? []) {
      if (message.sequence > 0) {
        files.push({
          display_name: `Freshdesk Backup`,
          date: message.sent_at,
          s3_uri,
          deduction_id: deduction.id,
        })
      }
    }
  }
  if (deduction.backup_s3_uri) {
    files.push({
      display_name: `${capitalCase(deduction.source)} Backup`,
      // TODO should be date of the backup request.
      date: deduction.created_at,
      s3_uri: deduction.backup_s3_uri,
      deduction_id: deduction.id,
    })
  }
  if (deduction.check_s3_uri) {
    files.push({
      display_name: `${capitalCase(deduction.source)} Check`,
      date: deduction.check_date,
      s3_uri: deduction.check_s3_uri,
      deduction_id: deduction.id,
    })
  }
  return files.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
}

export function DeductionDetail({ params }: { params: { deduction_id: Uuid } }) {
  const [loading, setLoading] = useState(true)
  const [backupLoading, setBackupLoading] = useState(true)
  const [deduction, setDeduction] = useState<DeductionResponse>()
  const [backup, setBackup] = useState<Backup[]>([])
  const [emails, setEmails] = useState<EmailMessage[]>([])
  const [emailsLoading, setEmailsLoading] = useState(true)
  const [messages, setMessages] = useState<DisputeMessage[]>([])
  const [messagesLoading, setMessagesLoading] = useState(true)
  const [files, setFiles] = useState<DeductionFile[]>([])
  // todo: query for deduction and backup separately?? probably ok to just get those pieces of data at once
  const statusFlag = StatusFlag.use(sf => sf!)

  useAsyncEffect(async () => {
    const deductionRes = await api_fetch<DeductionResponse>(`/deduction/${params.deduction_id}`)
    const backupRes = await api_fetch<Backup[]>(`/deduction/${params.deduction_id}/backup`)
    const emailRes = await api_fetch<EmailMessage[]>(`/deduction/${params.deduction_id}/emails`)
    const messagesRes = await api_fetch<DisputeMessage[]>(
      `/deduction/${params.deduction_id}/messages`,
    )

    if (!deductionRes.ok) {
      setLoading(false)
      throw new Error("Failed to fetch deduction")
    }

    setDeduction(deductionRes.value.data)
    if (backupRes.ok) {
      setBackup(backupRes.value.data)
    }
    setBackupLoading(false)

    if (emailRes.ok) {
      setEmails(emailRes.value.data)
    }

    if (messagesRes.ok) {
      setMessages(messagesRes.value.data)
    }
    setEmailsLoading(false)
    setMessagesLoading(false)
    setLoading(false)
  }, [statusFlag])

  if (!deduction || loading || emailsLoading || messagesLoading) {
    return <LoadingSpinner color="plue-500" />
  }

  useEffect(() => {
    setFiles(getFiles(deduction, emails, messages))
  }, [emails, deduction, messages])

  return (
    <div>
      <BreadcrumbNav items={[{ label: "Deductions", href: "/deductions" }, {href: "#", label: deduction.invoice_number}]} />
      <Header title={`Deduction Details`} deduction={deduction} />
      <DeductionCard deduction={deduction} />
      {/* TODO: stack the updates and files on top of each other. make convos wider */}
      <div class="columns-2 gap-4 mt-4">
        <div class="flex flex-col gap-4">
          <Updates deduction={deduction} backup={backup[0]} emails={emails} />
          <Files files={files} />
        </div>
        <Conversations deduction={deduction} emails={emails} messages={messages} />
      </div>
      <div class="w-full min-h-44">
        <BackupTable loading={backupLoading} columns={backup_columns} data={backup} />
      </div>
    </div>
  )
}

function Header({ title, deduction }: { title: string; deduction: DeductionResponse }) {
  return !deduction.dispute_id ? (
    <div className="flex items-center justify-between py-2 border-b ">
      <h1 className="text-2xl">{title}</h1>
      <div className="flex gap-2 ml-auto">
        <FileDisputeDrawer
          deduction={deduction}
          trigger={
            <Button variant="tertiary">
              <ShieldAlert className="w-4 mr-1" />
              File Dispute
            </Button>
          }
        />
      </div>
    </div>
  ) : null
}

function DeductionCard({ deduction }: { deduction: DeductionResponse }) {
  const KEYS_TO_DISPLAY = [
    { key: "invoice_number", displayKey: "Invoice #" },
    { key: "invoice_date", displayKey: "Invoice Date" },
    { key: "check_number", displayKey: "Check #" },
    { key: "po_number", displayKey: "PO #" },
    { key: "invoice_amount", displayKey: "Invoice Amount" },
    { key: "category", displayKey: "Category" },
    { key: "description", displayKey: "Distributor Description" },
    { key: "retailer_name", displayKey: "Retailer" },
    {
      key: "source",
      displayKey: "Distributor",
      customRender: (value: string) => formatDistributor(value),
    },
    {
      key: "status_value",
      displayKey: "Status",
      customRender: (value: string | undefined) => {
        const iconKey = (value?.toLowerCase() as keyof typeof STATUS_ICONS) || "new"
        const Icon = STATUS_ICONS[iconKey]
        return value ? (
          <Badge
            className="pl-1 rounded-sm text-center"
            variant={value.toLowerCase() as BadgeVariantOptionType}>
            {Icon}
            {capitalCase(value)}
          </Badge>
        ) : (
          "-"
        )
      },
    },

    // todo: notes, backup deduction (product, promo type / dates, win confidence)
  ]

  return (
    <Card className="overflow-x-auto py-2 bg-white mt-6">
      <div className="flex justify-between items-center">
        {KEYS_TO_DISPLAY.map(({ key, displayKey, customRender }, index) => (
          <>
            <div className="flex flex-col items-center justify-center mx-4">
              <div className="text-sm text-gray-500">{displayKey}</div>
              <div className="text-sm mt-2">
                {customRender
                  ? customRender((deduction[key as keyof DeductionResponse] as string) ?? "")
                  : deduction[key as keyof DeductionResponse] ?? "-"}
              </div>
            </div>
            {index < KEYS_TO_DISPLAY.length - 1 && ( // Only add separators between items, not after the last one
              <Separator orientation="vertical" className="h-full " />
            )}
          </>
        ))}
      </div>
    </Card>
  )
}

if (import.meta.vitest) {
  describe('getFiles function', () => {
    const mockDeduction = {
      id: 'deduction1',
      source: 'email',
      backup_s3_uri: 's3://backup/123',
      check_s3_uri: 's3://check/456',
      created_at: '2022-01-01',
      check_date: '2022-01-02'
    }
  
    it('should process email attachments correctly', () => {
      const emails = [{
        date: '2022-01-03',
        attachments: [
          { s3_uri: 's3://attachments/789' },
          { s3_uri: 's3://attachments/101' }
        ]
      }]
          // @ts-ignore
      const result = getFiles(mockDeduction, emails, [])
      expect(result).toHaveLength(4)  // 2 attachments + backup + check
      expect(result[0]).toHaveProperty('display_name', '789')
      expect(result[1]).toHaveProperty('display_name', '101')
    })
  
    it('should skip email attachments that match backup or check URIs', () => {
      const emails = [{
        date: '2022-01-03',
        attachments: [
          { s3_uri: 's3://backup/123' },
          { s3_uri: 's3://check/456' },
          { s3_uri: 's3://attachments/789' }
        ]
      }]
          // @ts-ignore
      const result = getFiles(mockDeduction, emails, [])
      expect(result).toHaveLength(3)  // 1 attachment + backup + check
      expect(result[0]).toHaveProperty('display_name', '789')
    })
  
    it('should process dispute messages correctly', () => {
      const messages = [{
        sent_at: '2022-01-04',
        attachment_s3_paths: ['s3://messages/123', 's3://messages/456'],
        sequence: 1
      }]
          // @ts-ignore
      const result = getFiles(mockDeduction, [], messages)
      expect(result).toHaveLength(4)  // 2 message attachments + backup + check
      expect(result[0]).toHaveProperty('display_name', 'Freshdesk Backup')
      expect(result[1]).toHaveProperty('display_name', 'Freshdesk Backup')
    })
  
    it('should skip dispute messages with sequence 0', () => {
      const messages = [{
        sent_at: '2022-01-04',
        attachment_s3_paths: ['s3://messages/123'],
        sequence: 0
      }]
          // @ts-ignore
      const result = getFiles(mockDeduction, [], messages)
      expect(result).toHaveLength(2)  // Only backup + check
    })
  
    it('should include backup and check files when present', () => {
          // @ts-ignore
      const result = getFiles(mockDeduction, [], [])
      expect(result).toHaveLength(2)
      expect(result[0]).toHaveProperty('display_name', 'Email Check')
      expect(result[1]).toHaveProperty('display_name', 'Email Backup')
    })
  
    it('should handle missing backup or check URIs', () => {
      const deductionWithoutURIs = { ...mockDeduction, backup_s3_uri: null, check_s3_uri: null }
          // @ts-ignore
      const result = getFiles(deductionWithoutURIs, [], [])
      expect(result).toHaveLength(0)
    })
  
    it('should sort files by date in descending order', () => {
      const emails = [
        { date: '2022-01-03', attachments: [{ s3_uri: 's3://attachments/789' }] },
        { date: '2022-01-05', attachments: [{ s3_uri: 's3://attachments/101' }] }
      ]
      const messages = [
        { sent_at: '2022-01-04', attachment_s3_paths: ['s3://messages/123'], sequence: 1 }
      ]
          // @ts-ignore
      const result = getFiles(mockDeduction, emails, messages)
      expect(result).toHaveLength(5)
      expect(result[0]).toHaveProperty('date', '2022-01-05')
      expect(result[1]).toHaveProperty('date', '2022-01-04')
      expect(result[2]).toHaveProperty('date', '2022-01-03')
      expect(result[3]).toHaveProperty('date', '2022-01-02')
      expect(result[4]).toHaveProperty('date', '2022-01-01')
    })
  
    it('should handle empty inputs', () => {
          // @ts-ignore
      const result = getFiles({...mockDeduction, backup_s3_uri: null, check_s3_uri: null}, [], [])
      expect(result).toHaveLength(0)
    })
  
    it('should use "Email Attachment" as display name when s3_uri is empty', () => {
      const emails = [{
        date: '2022-01-03',
        attachments: [{ s3_uri: '' }]
      }]
      // @ts-ignore
      const result = getFiles(mockDeduction, emails, [])
      expect(result).toHaveLength(3)  // 1 attachment + backup + check
      expect(result[0]).toHaveProperty('display_name', 'Email Attachment')
    })
  })
}
