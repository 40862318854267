import { useState } from "preact/compat"
import { api_fetch } from "../../api/client.tsx"
import { useAsyncEffect, useSearchParams } from "../../utils/util.tsx"
import { MonthlyBreakdownChart } from "./monthly_breakdown.tsx"
import { columns } from "./sales_columns.ts"
import { DataTable } from "../deductions/table/data_table.tsx"
import { DAY_COUNTS, dayCountToString, DISTRIBUTORS } from "../deductions/index.tsx"
import { Deduction, DeductionResponse } from "@/api/deduction.tsx"
import { StatusFlag } from "../deductions/status_state.tsx"
import { GenericFilter } from "../component/filter.tsx"
import { BreadcrumbNav, DataTableState } from "../common.tsx"

function SalesNavContext() {
  const salesNavItems = [{
    //   label: "Dashboard",
    //   href: "/dashboard",
    // },{
    label: "Sales",
    href: "/sales",
  }]
  return (
    <BreadcrumbNav items={salesNavItems} />
  )
}

export function Sales() {
  const params = useSearchParams<{ search?: string, days?: number, distributor?: string }>()
  const statusFlag = StatusFlag.use(sf => sf!)


  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any[]>([])
  const tableState = DataTableState.use()

  const {
    days: selectedDay = DAY_COUNTS[8],
    distributor: selectedDistributor = DISTRIBUTORS[0],
  } = tableState

  function handleDayCountChange(days: number) {
    DataTableState.set({ ...tableState, days })
  }

  function handleDistributorChange(distributor: string) {
    DataTableState.set({ ...tableState, distributor })
  }

  useAsyncEffect(async () => {
    let distributor = selectedDistributor === "All Distributors" ? "" : selectedDistributor
    let p = Object.assign({}, params, { days: selectedDay, distributor, query_type: "sales" })
    const res = await api_fetch<Deduction[]>("/deduction", { params: p })

    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }

    setLoading(false)

    // convert null values to empty strings to ensure filtering works as expected
    const data = res.value.data.map(d => {
      return Object.fromEntries(Object.entries(d).map(([k, v]) => [k, v ?? ""])) as DeductionResponse
    })
    setData(data)
  }, [selectedDay, statusFlag, selectedDistributor])

  return (
    <div>
      <SalesNavContext />
      <MonthlyBreakdownChart />
      <GenericFilter
        items={DISTRIBUTORS}
        selectedItem={selectedDistributor}
        handleOnChange={handleDistributorChange}
        itemToString={(item) => item}
        className="absolute right-44 top-0 mt-6 w-36 mr-8"
      />
      <GenericFilter
        items={DAY_COUNTS}
        selectedItem={selectedDay}
        handleOnChange={handleDayCountChange}
        itemToString={dayCountToString}
        className="absolute right-8 top-0 mt-6 w-40 max-w-sm"
      />
      <div class="mt-8">
        <DataTable loading={loading} columns={columns} data={data} />
      </div>
    </div>
  )
}
