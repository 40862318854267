import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { Dot, NotebookPen } from "lucide-react"
import { formatDistanceToNow } from "date-fns"
import { useState } from "preact/hooks"
import { api_fetch } from "src/api/client.tsx"
import { StatusFlag } from "../../status_state"

interface NoteContentProps {
  note: any;
}

function NoteContent({ note }: NoteContentProps) {
  return (
    <div className="flex flex-col mb-2">
      <div className="mt-2 mb-1 rounded-tl-lg rounded-tr-lg rounded-br-lg bg-plue-100 p-2 text-sm text-plue-700">
        {note?.message}
      </div>
      <div className="flex items-center text-sm">
        <span className="text-gray-900">{note?.actor_name}</span>
        <Dot className="h-2 w-2 mx-1" />
        <span>{formatDistanceToNow(new Date(note?.updated_at), { addSuffix: true })}</span>
      </div>
    </div>
  )
}

interface NotesDialogContentProps {
  deduction_id: string;
  notes: any[];
}

export async function handleSaveNote({ deduction_id, note, setNote }: {
  deduction_id: string,
  note: string,
  setNote: any
}) {
  const res = await api_fetch(`/deductions/note`, {
    method: "POST",
    body: {
      deduction_id: deduction_id,
      note,
    },
  })

  if (res.ok) {
    setNote("")
    StatusFlag.set(flag => !flag) // hack for now to force a re-render
  } else {
    console.error("Failed to save note")
  }
}

export function Notes({ deduction_id, notes }: NotesDialogContentProps) {
  const [note, setNote] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false) // Now managed within the component

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger>
        {notes.length > 0 ? (
          <NotebookPen className="h-5 w-5 cursor-pointer text-gray-900" />
        ) : (
          <NotebookPen className="h-5 w-5 cursor-pointer text-gray-400" />
        )}
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle className="mb-2">Notes</DialogTitle>
          <DialogDescription className="overflow-y-auto max-h-96">
            {notes.length > 0 ? notes.map((note) => <NoteContent note={note} key={note.id} />) : null}
          </DialogDescription>
        </DialogHeader>
        <Textarea
          // @ts-ignore
          value={note}
          onChange={(e: any) => setNote(e.target.value)}
          onKeyDown={(e: any) => {
            if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
              e.preventDefault()
              handleSaveNote({ deduction_id, note, setNote })
            }
          }}
        />
        <Button onClick={() => handleSaveNote({ deduction_id, note, setNote })}>Save</Button>
      </DialogContent>
    </Dialog>
  )
}
