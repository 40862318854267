import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { useState } from "preact/compat"
import { api_fetch } from "../../api/client.tsx"
import { useAsyncEffect } from "../../utils/util.tsx"
import { Cell, currencyFormatter, QueryData } from "../common.tsx"
import { cn } from "@/utils.ts"
import { CategoryBarChart } from "./category_bar_chart.tsx"
import { StatusBarChart } from "./status_bar_chart.tsx"

export function LoadingSpinner({ color }: { color: string }) {
  let cls = `w-8 h-8 border-t-4 border-${color}-500 border-solid rounded-full animate-spin`
  return <div class={cls} />
}

export function SummaryCards({ selectedDay, selectedDistributor }: {
  selectedDay: number,
  selectedDistributor: string
}) {
  const [data, setData] = useState<QueryData>()
  const [loading, setLoading] = useState(true)

  useAsyncEffect(async () => {
    let distributor = selectedDistributor === "All Distributors" ? "" : selectedDistributor
    let res = await api_fetch<QueryData>(`/deductions/query`, { params: { days: selectedDay, distributor, query_name: "summary" } })
    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }
    setLoading(false)
    setData(res.value.data)
  }, [selectedDay, selectedDistributor])

  if (loading) {
    return <LoadingSpinner color="blue" />
  }

  const row = data?.rows[0]!

  return (
    <div className="flex flex-wrap gap-4 py-4 pt-6">
      <div className="flex flex-wrap w-full">
        <div className="flex flex-wrap w-2/3 gap-4">
          <Card className="h-fit w-[calc(50%-0.5rem)]">
            <CardBody
              value={row[0]}
              total={`${row[1]} purchase orders`}
              title="Total Sales"
              valueFormatter={currencyFormatter}
            />
          </Card>
          <Card className="h-fit w-[calc(50%-0.5rem)]">
            <CardBody
              value={row[2]}
              total={`${row[3]} deductions on invoices`}
              title="Total Deductions"
              valueFormatter={currencyFormatter}
              color="red-800"
            />
          </Card>
          <Card className="h-fit w-[calc(50%-0.5rem)]">
            <CardBody
              value={`${currencyFormatter(Math.abs(row[6] as number))} - ${currencyFormatter(Math.abs(row[7] as number))}`}
              total="Estimated"
              title="Winnable Disputes"
              color="green-700"
            />
          </Card>
          <Card className="h-fit w-[calc(50%-0.5rem)]">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-md font-medium">Current Status Breakdown</CardTitle>
              <div>
                Gross Deductions
              </div>
            </CardHeader>
            <CardContent>
              <StatusBarChart selectedDay={selectedDay} selectedDistributor={selectedDistributor} />
            </CardContent>
          </Card>
        </div>
        <div className="w-1/3 pl-4">
          <Card className="h-fit">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-md font-medium">Category Breakdown</CardTitle>
            </CardHeader>
            <CardContent>
              <CategoryBarChart selectedDay={selectedDay} selectedDistributor={selectedDistributor} />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

interface SummaryCardData {
  title?: string
  color?: string
  total?: Cell,
  value: Cell,
  valueFormatter?: (value: number) => string,
  onClick?: () => void
}

export function CardBody({ title, color, total, value, valueFormatter, onClick }: SummaryCardData) {
  return (
    <div onClick={onClick}>
      {title && (
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
          <CardTitle className="text-md font-medium">{title}</CardTitle>
        </CardHeader>
      )}
      <CardContent>
        <div className={cn("text-3xl font-bold", color && `text-${color}`)}>
          {valueFormatter ? valueFormatter(value as number) : value}
        </div>
        {total && <p className="text-sm text-muted-foreground pt-4">{total}</p>}
      </CardContent>
    </div>
  )
}
