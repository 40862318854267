import { useSearchParams } from "src/utils/util.tsx"
import { useLocation } from "wouter-preact"
import { api_fetch } from "../api/client.tsx"
import { User } from "../api/user.tsx"
import { useFormState } from "../component/form/form_state.tsx"
import { LoginScreen } from "../component/LoginScreen.tsx"
import { UserState } from "./user.tsx"

import { Logo } from "../app/logo.tsx"
import { Form } from "../component/form/Form.tsx"
import { EmailInput, PasswordInput } from "../component/form/Input.tsx"
import { Button } from "@/components/ui/button.tsx"

interface RegisterResponse extends User {
  skip_setup: boolean
}

export function Signup() {
  const params = useSearchParams<{ next?: string; token?: string, email?: string }>()

  const [, setLocation] = useLocation()
  const form = useFormState({
    email: params.email || "",
    password: "",
  })


  async function onSubmit() {
    const res = await api_fetch<RegisterResponse>("/auth/register_password", {
      body: form.form_data,
    })
    if (!res.ok) {
      form.set_form_errors([res.error.message])
      return
    }
    UserState.set(res.value.data)

    const next = params.next ?? (res.value.data.skip_setup ? "/deductions" : "/setup/kehe")
    setLocation(next)
  }

  return (
    <LoginScreen title="Sign up for your account" logo={<Logo />}>
      <p className="text-sm font-medium text-gray-900">
        After this, we'll walk you through linking your KeHE and email accounts. It should only take a few minutes.
      </p>
      <Form
        class="space-y-6"
        onSubmit={onSubmit}
        alerts={form.form_alerts}
      >
        <EmailInput
          label="Email address"
          required={true}
          bind={form.email}
        />
        <PasswordInput
          label="Password"
          required={false}
          bind={form.password}
        />
        <Button
          type="submit"
          className="w-full btn btn-primary rounded-md 
                            focus:outline-none focus:ring-1 focus:ring-offset-2 "
        >
          Sign up
        </Button>
      </Form>
    </LoginScreen>
  )
}
