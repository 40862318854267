"use client"

import { DeductionResponse } from "@/api/deduction.tsx"
import { Badge } from "@/components/ui/badge"

import { Checkbox } from "@/components/ui/checkbox"
import {
  cellCurrencyFormatter,
  cellDateFormatter,
} from "@/dashboard/deductions/detail/backup_columns.tsx"
import { handleViewS3Uri } from "@/dashboard/deductions/detail/files.tsx"
import {
  AccessorColumnDef,
  CellContext,
  DisplayColumnDef,
  GroupColumnDef,
  RowSelectionInstance,
} from "@tanstack/react-table"
import { capitalCase } from "change-case"

import { classes } from "@/utils/util.tsx"
import {
  Check,
  CircleCheckIcon,
  Copy,
  Dot,
  Ellipsis,
  Eye,
  File,
  FileCheck,
  FileCog,
  Paperclip,
  Receipt,
  ShieldQuestion,
  Trophy,
} from "lucide-react"
import { formatDistributor } from "src/utils/util.tsx"
import { ColumnHeader } from "./column_header"
import { Actions } from "./actions"
import { useEffect, useRef, useState } from "preact/hooks"
import { SimpleSelect } from "@/dashboard/component/filter"
import { api_fetch } from "@/api/client"
import { copyToClipboard } from "./util"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { CATEGORY_FORM_SCHEMA, CategoryForm } from "./category_form"
import { Button } from "@/components/ui/button"
import { z } from "zod"
import { StatusFlag } from "../status_state"

export const STATUS_ICONS = {
  new: <Dot className="h-4 w-4 text-orange-700" />,
  review: <Eye className="h-4" />,
  validated: <CircleCheckIcon fill="green" className="h-4 text-white" />,
  won: <Trophy className="h-4" />,
  pending: <Ellipsis className="h-4" />,
  disputed: <Ellipsis className="h-4" />,
  backup_requested: <FileCog className="h-4" />,
  backup_received: <FileCheck className="h-4" />,
  processing: <Receipt className="h-4" />,
  disputable: <ShieldQuestion className="h-4" />,
}

export const CATEGORY_COLORS = {
  "Retailer Promotion": "amber",
  "Operational Issues": "emerald",
  "Free Fill": "fuchsia",
  "Distributor Promotion": "sky",
  "Contractual Fees": "rose",
  "Misc": "slate",
  "Freight": "teal",
  "Invoice Discrepancy": "lime",
  "Slotting": "indigo",
  "Spoilage": "emerald",
  "Invoice Adjustment": "lime",
}

export const invoiceAmountColumn: DeductionTableColumn = {
  accessorKey: "invoice_amount",
  enableHiding: false,
  // enableGlobalFilter: false,
  header: ({ column }) => <ColumnHeader className="justify-end" column={column} title="Amount" />,
  cell: cellCurrencyFormatter,
}

export const invoiceNumberColumn: DeductionTableColumn = {
  accessorKey: "invoice_number",
  enableHiding: false,
  header: ({ column }) => <ColumnHeader column={column} title="Invoice #" />,
  cell: ({ getValue }) => {
    const invoiceNumber = getValue() as string
    const [showCopyConfirmed, setShowCopyConfirmed] = useState<boolean>(false)

    const handleCopy = () => {
      copyToClipboard(invoiceNumber)
      setShowCopyConfirmed(true)
      setTimeout(() => {
        setShowCopyConfirmed(false)
      }, 2000)
    }

    return (
      <div className="flex items-center" onClick={handleCopy}>
        <span>{invoiceNumber}</span>
        {showCopyConfirmed ? (
          <Check className="ml-2 h-4 w-4 text-green-500" />
        ) : (
          <Copy
            className="ml-2 h-4 w-4 cursor-pointer hover:text-plue-500"
            title="Copy Invoice Number"
          />
        )}
      </div>
    )
  },
}

export const createDateColumn = (accessorKey: string, title: string): DeductionTableColumn => ({
  accessorKey: accessorKey as keyof DeductionResponse,
  enableHiding: false,
  enableGlobalFilter: false,
  header: ({ column }) => <ColumnHeader column={column} title={title} />,
  cell: cellDateFormatter,
})

export const distributorColumn: DeductionTableColumn = {
  accessorKey: "source",
  enableHiding: false,
  header: ({ column }) => <ColumnHeader column={column} title="Dist" />,
  cell: cell => {
    let value = cell.getValue()
    if (!value) return ""
    return formatDistributor(value as string)
  },
}

export const dcColumn: DeductionTableColumn = {
  accessorKey: "dc",
  header: ({ column }) => <ColumnHeader column={column} title="DC" />,
}

export const dcNameColumn: DeductionTableColumn = {
  accessorKey: "dc_name",
  header: ({ column }) => <ColumnHeader column={column} title="DC Name" />,
  enableGlobalFilter: false,
}

export const statusColumn: DeductionTableColumn = {
  accessorKey: "status_value",
  header: ({ column }) => <ColumnHeader column={column} title="Stage" />,
  cell: cell => {
    const STATUS_VALUES = {
      new: "New",
      validated: "Validated",
      disputable: "Disputable",
      backup_requested: "Backup Requested",
      backup_received: "Backup Received",
      review: "Review",
      disputed: "Disputed",
      won: "Won",
      lost: "Lost",
    }

    // @ts-ignore
    let initialValue = cell.getValue("status_value") || "new"

    // @ts-ignore
    if (cell.row.original["HasDispute"]) {
      initialValue = "disputed"
    }
    // @ts-ignore
    if (cell.row.original["PayStatusCode"]?.toLowerCase().includes("in progress")) {
      initialValue = ""
    }

    const handleOnChange = async (newStatus: string) => {
      const res = await api_fetch(`/deductions/status`, {
        method: "POST",
        body: {
          deduction_id: cell.row.original.id,
          status: newStatus,
        },
      })

      if (res.ok) {
        console.log("Status updated successfully")
        // StatusFlag.set(flag => !flag) // don't re-render to allow someone to undo / edit
      } else {
        console.error("Failed to update status")
      }
    }

    return (
      <SimpleSelect
        // @ts-ignore
        value={initialValue}
        onChange={handleOnChange}
        values={STATUS_VALUES}
        icons={STATUS_ICONS}
      />
    )
  },
}

export const filesColumn: DeductionTableColumn = {
  id: "files", // named this so it's consistent in the column dropdown filter
  header: ({ column }) => <ColumnHeader column={column} title="Files" />,
  enableGlobalFilter: false,
  cell: cell => {
    let buttons = []
    let backup_s3_uri = cell.row.original.backup_s3_uri
    if (backup_s3_uri) {
      buttons.push(
        <div
          onClick={handleViewS3Uri(backup_s3_uri)}
          className="cursor-pointer text-primary underline pr-2">
          <Paperclip className="w-4 h-4" />
        </div>
      )
    }
    let check_s3_uri = cell.row.original.check_s3_uri
    if (check_s3_uri) {
      buttons.push(
        <div
          onClick={handleViewS3Uri(check_s3_uri)}
          className="cursor-pointer text-primary underline pr-2">
          <File className="w-4 h-4" />
        </div>
      )
    }
    if (!buttons.length) return ""
    return <div class="flex">{buttons}</div>
  },
}

export const actionsColumn: DeductionTableColumn = {
  id: "actions",
  header: ({ column }) => <ColumnHeader column={column} title="" />,
  enableGlobalFilter: false,
  cell: ({ row }) => {
    return <Actions deduction={row.original} />
  },
}

export const taskColumn: DeductionTableColumn = {
  id: "task",
  accessorFn: row => `${row.task?.task_status}: ${row.task?.task_user_email?.split("@")[0]}`,
  header: ({ column }) => <ColumnHeader column={column} title="Task" />,
  enableSorting: true,
  cell: ({ row }) => {
    let task_user_email = row.original.task?.task_user_email
    if (!task_user_email) return ""

    return `${row.original.task?.task_status}: ${task_user_email.split("@")[0]}`
  },
}

export const selectColumn: DeductionTableColumn = {
  id: "select",
  header: ({ table }: { table: RowSelectionInstance<DeductionResponse> }) => (
    <Checkbox
      checked={
        table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")
      }
      onCheckedChange={(value: any) => table.toggleAllPageRowsSelected(!!value)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onCheckedChange={(value: any) => row.toggleSelected(!!value)}
      aria-label="Select row"
    />
  ),
  enableSorting: false,
  enableHiding: false,
  enableGlobalFilter: false,
}

export const categoryColumn: DeductionTableColumn = {
  accessorKey: "category",
  header: ({ column }) => <ColumnHeader column={column} title="Category" />,
  cell: cell => {
    let value = cell.getValue() as "" | keyof typeof CATEGORY_COLORS
    if (!value) {
      value = "Misc"
    }
    let color = value ? CATEGORY_COLORS[value] : "gray"
    const [categoryPopoverOpen, setCategoryPopoverOpen] = useState(false)

    const handleSubmit = async (values: z.infer<typeof CATEGORY_FORM_SCHEMA>) => {
      const res = await api_fetch(`/deduction/${cell.row.original.id}/category`, {
        method: "POST",
        body: values,
      })

      if (res.ok) {
        console.log("Category updated successfully")
      } else {
        console.error("Failed to update category")
      }

      setCategoryPopoverOpen(false)
      StatusFlag.set(flag => !flag)
    }

    return (
      <Popover modal={true} open={categoryPopoverOpen} onOpenChange={setCategoryPopoverOpen}>
        <PopoverTrigger>
          {" "}
          <Badge
            className={classes(
              `bg-${color}-100 text-${color}-700 hover:bg-${color}-200`,
              "text-center"
            )}>
            {capitalCase(value)}
          </Badge>
        </PopoverTrigger>
        <PopoverContent collisionPadding={200} className="text-plue-900">
          <CategoryForm
            initialValue={value}
            deductionId={cell.row.original.id}
            onSubmit={handleSubmit}
          />
        </PopoverContent>
      </Popover>
    )
  },
}

export const descriptionColumn: DeductionTableColumn = {
  accessorKey: "description",
  header: ({ column }) => <ColumnHeader column={column} title="Description" />,
}
export const retailerColumn: DeductionTableColumn = {
  accessorKey: "retailer_name",
  header: ({ column }) => <ColumnHeader column={column} title="Retailer" />,
}

export type TableColumn<T> =
  | DisplayColumnDef<T, unknown>
  | GroupColumnDef<T, unknown>
  | (AccessorColumnDef<T, unknown> & {
      accessorKey: keyof T
    })

export type DeductionTableColumn = TableColumn<DeductionResponse>

export const COLUMNS: DeductionTableColumn[] = [
  selectColumn,
  createDateColumn("invoice_date", "Invoice Date"),
  invoiceNumberColumn,
  distributorColumn,
  categoryColumn,
  descriptionColumn,
  retailerColumn,
  invoiceAmountColumn,
  {
    accessorKey: "check_amount",
    enableGlobalFilter: false,
    header: ({ column }) => (
      <ColumnHeader className="justify-end" column={column} title="Check Amount" />
    ),
    cell: cellCurrencyFormatter,
  },
  {
    accessorKey: "check_number",
    header: ({ column }) => (
      <ColumnHeader className="justify-end" column={column} title="Check Number" />
    ),
  },
  createDateColumn("execution_date", "Execution Date"),
  statusColumn,
  filesColumn,
  actionsColumn,
  taskColumn,
  dcColumn,
  dcNameColumn,
]
