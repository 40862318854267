import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "@/components/ui/command"
import { BookOpenCheck, Check, ChevronsUpDown } from "lucide-react"
import { useState } from "preact/hooks"
import { classes, useAsyncEffect } from "@/utils/util"
import { ReasonCode } from "../api/deduction"
import { api_fetch } from "@/api/client"

interface AccountingMatchPopoverProps {
  onSelectMatch: (match: ReasonCode) => void
}

export const AccountingMatchPopover = ({ onSelectMatch }: AccountingMatchPopoverProps) => {
  const [open, setOpen] = useState(false)
  const [selectedCode, setSelectedCode] = useState<ReasonCode>()
  const [codes, setCodes] = useState<ReasonCode[]>([])

  useAsyncEffect(async () => {
    const res = await api_fetch<ReasonCode[]>("/reason_codes")
    if (res.ok) {
      // TODO: ideally group these by the Promoted category they belong to and render them in a grouped list
      setCodes(res.value.data.sort((a, b) => a.name.localeCompare(b.name)))
    }
  }, [])

  const getCodeLabel = (code?: ReasonCode): string | undefined => {
    if (!code) return undefined;
    const expenseAccountParts = code.expense_account?.split(":");
    const lastPart = expenseAccountParts ? expenseAccountParts[expenseAccountParts.length - 1] : "";
    return `${code.name}${lastPart ? `: ${lastPart}` : ""}`;
  }

  const handleSelectCode = (codeLabel: string, code: ReasonCode) => {
    if (codeLabel === getCodeLabel(selectedCode)) {
        setSelectedCode(undefined)
        } else {
            setSelectedCode(code)
            onSelectMatch(code)
        }
        setOpen(false)
    }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <Button className="pr-2 bg-yellow-500-opacity-10">
          {selectedCode ? "Updated" : "Select code..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          <BookOpenCheck className="h-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command>
          <CommandInput placeholder="Search reason code..." />
          <CommandList>
            <CommandEmpty>No codes found. Email joey@promotedtpm.com to get set up.</CommandEmpty>
            <CommandGroup>
              {codes.map((code, index) => (
                <CommandItem
                  key={code.id}
                  value={code}
                  className={index % 2 === 0 ? "bg-plue-50" : ""} // Alternating background colors
                  onSelect={(currentValue: string) => handleSelectCode(currentValue, code)}>
                  <Check
                    className={classes(
                      "mr-2 h-4 w-4",
                      selectedCode === code ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {getCodeLabel(code)}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

// WIP: grouping by category...
// "use client"

// import * as React from "react"
// import { Check, ChevronsUpDown } from "lucide-react"

// import { cn } from "@/lib/utils"
// import { Button } from "@/components/ui/button"
// import {
//   Command,
//   CommandEmpty,
//   CommandGroup,
//   CommandInput,
//   CommandItem,
//   CommandList,
//   CommandSeparator,
// } from "@/components/ui/command"
// import {
//   Popover,
//   PopoverContent,
//   PopoverTrigger,
// } from "@/components/ui/popover"

// const frameworkGroups = [
//   {
//     label: "React Frameworks",
//     frameworks: [
//       { value: "next.js", label: "Next.js" },
//       { value: "remix", label: "Remix" },
//     ],
//   },
//   {
//     label: "Vue Frameworks",
//     frameworks: [
//       { value: "nuxt.js", label: "Nuxt.js" },
//       { value: "vuejs", label: "Vue.js" },
//     ],
//   },
//   {
//     label: "Other Frameworks",
//     frameworks: [
//       { value: "sveltekit", label: "SvelteKit" },
//       { value: "astro", label: "Astro" },
//     ],
//   },
// ]

// export function ComboboxDemo() {
//   const [open, setOpen] = React.useState(false)
//   const [value, setValue] = React.useState("")

//   return (
//     <Popover open={open} onOpenChange={setOpen}>
//       <PopoverTrigger asChild>
//         <Button
//           variant="outline"
//           role="combobox"
//           aria-expanded={open}
//           className="w-[200px] justify-between"
//         >
//           {value
//             ? frameworkGroups.flatMap(group => group.frameworks).find((framework) => framework.value === value)?.label
//             : "Select framework..."}
//           <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
//         </Button>
//       </PopoverTrigger>
//       <PopoverContent className="w-[200px] p-0">
//         <Command>
//           <CommandInput placeholder="Search framework..." />
//           <CommandList>
//             <CommandEmpty>No framework found.</CommandEmpty>
//             {frameworkGroups.map((group, index) => (
//               <React.Fragment key={group.label}>
//                 {index > 0 && <CommandSeparator />}
//                 <CommandGroup heading={group.label}>
//                   {group.frameworks.map((framework) => (
//                     <CommandItem
//                       key={framework.value}
//                       value={framework.value}
//                       onSelect={(currentValue) => {
//                         setValue(currentValue === value ? "" : currentValue)
//                         setOpen(false)
//                       }}
//                     >
//                       <Check
//                         className={cn(
//                           "mr-2 h-4 w-4",
//                           value === framework.value ? "opacity-100" : "opacity-0"
//                         )}
//                       />
//                       {framework.label}
//                     </CommandItem>
//                   ))}
//                 </CommandGroup>
//               </React.Fragment>
//             ))}
//           </CommandList>
//         </Command>
//       </PopoverContent>
//     </Popover>
//   )
// }