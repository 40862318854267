import { ColumnHeader } from "@/dashboard/deductions/table/column_header"
import { actionsColumn, categoryColumn, createDateColumn, DeductionTableColumn, descriptionColumn, distributorColumn, filesColumn, invoiceAmountColumn, invoiceNumberColumn, selectColumn, statusColumn, taskColumn } from "../dashboard/deductions/table/columns"

export const columns: DeductionTableColumn[] = [
  selectColumn,
  createDateColumn("invoice_date", "Invoice Date"),
  invoiceNumberColumn,
  distributorColumn,
  categoryColumn,
  descriptionColumn,
  invoiceAmountColumn,
  filesColumn,
  statusColumn,
  {
    id: "reason_code",
    accessorFn: (row) => row.reason_code?.name,
    header: ({ column }) => <ColumnHeader column={column} title="Reason Code" />,
    enableSorting: true,
    cell: ({ row }) => row.original.reason_code?.name ,
  },
  {
    id: "expense_account",
    accessorFn: (row) => row.reason_code?.expense_account,
    header: ({ column }) => <ColumnHeader column={column} title="Expense Account" />,
    enableSorting: true,
    cell: ({ row }) => row.original.reason_code?.expense_account,
  },
  {
    id: "reason_code_actor",
    accessorFn: (row) => row.reason_code?.actor,
    header: ({ column }) => <ColumnHeader column={column} title="Coded by" />,
    enableSorting: true,
    cell: ({ row }) => row.original.reason_code?.actor,
  },
  actionsColumn,
  taskColumn,
  {
    id: "reason_code_type",
    accessorFn: (row) => row.reason_code?.code_type,
    header: ({ column }) => <ColumnHeader column={column} title="Code Type" />,
    enableSorting: true,
    cell: ({ row }) => row.original.reason_code?.code_type,
  },
  createDateColumn("check_date", "Check Date"),
//   todo: difference between check date and invoice date in days or is that not the payment date??
]