import { Deduction } from "@/api/deduction.tsx"

export enum DisputeType {
  Backup = "backup",
  Chargeback = "chargeback",
  EarlyPay = "early-pay",
}

export interface FileDisputeForm {
  deduction_id: string
  type: DisputeType,
  message: string,
}

export function build_dispute_form(data: FileDisputeForm) {
  let formData = new FormData()
  formData.append("deduction_id", data.deduction_id)
  formData.append("dispute_type", data.type)
  formData.append("message", data.message)
  return formData
}

export async function bulk_request_backup(deductions: Deduction[]) {
  let forms = []
  for (const d of deductions) {
    let message = `Please provide backup for this charge. The deduction invoice number is ${d.invoice_number}.`
    forms.push(build_dispute_form({
      deduction_id: d.id,
      type: DisputeType.Backup,
      message,
    }))
  }
  for (const form of forms) {
    let res = await fetch("/api/dispute", {
      method: "POST",
      credentials: "same-origin",
      body: form,
    })
    if (!res.ok) {
      console.error("Failed to request backup")
    }
  }
}

export interface FileDispute {
  deduction: Deduction,
  message: string,
  dispute_amount: number,
  files: File[],
}

export async function file_dispute(d: FileDispute) {
  let form = build_dispute_form({
    deduction_id: d.deduction.id,
    type: DisputeType.Chargeback,
    message: d.message,
  })
  form.append("dispute_amount", d.dispute_amount.toString())
  for (const f of d.files) {
    form.append("files", f)
  }
  return await fetch("/api/dispute", {
    method: "POST",
    credentials: "same-origin",
    body: form,
  })
}

if (import.meta.vitest) {
  const mockFetch = vi.fn()
  vi.stubGlobal('fetch', mockFetch)
  
  describe('dispute helpers', () => {
    describe('build_dispute_form', () => {
      it('should create a FormData object with correct fields', () => {
        const data = {
          deduction_id: '123',
          type: DisputeType.Backup,
          message: 'Test message',
        }
        const formData = build_dispute_form(data)
        
        expect(formData.get('deduction_id')).toBe('123')
        expect(formData.get('dispute_type')).toBe('backup')
        expect(formData.get('message')).toBe('Test message')
      })
    })
  
    describe('bulk_request_backup', () => {
      beforeEach(() => {
        mockFetch.mockReset()
        mockFetch.mockResolvedValue({ ok: true })
        vi.spyOn(console, 'error').mockImplementation(() => {})
      })
  
      it('should create and send a form for each deduction', async () => {
        const deductions: Deduction[] = [
          { id: '1', invoice_number: 'INV001' },
          { id: '2', invoice_number: 'INV002' },
        ] as Deduction[]
  
        await bulk_request_backup(deductions)
  
        expect(mockFetch).toHaveBeenCalledTimes(2)
        expect(mockFetch).toHaveBeenCalledWith('/api/dispute', expect.objectContaining({
          method: 'POST',
          credentials: 'same-origin',
        }))
      })
  
      it('should log an error if the request fails', async () => {
        mockFetch.mockResolvedValue({ ok: false })
        const deductions: Deduction[] = [{ id: '1', invoice_number: 'INV001' }] as Deduction[]
  
        await bulk_request_backup(deductions)
  
        expect(console.error).toHaveBeenCalledWith('Failed to request backup')
      })
    })
  
    describe('file_dispute', () => {
      beforeEach(() => {
        mockFetch.mockReset()
        mockFetch.mockResolvedValue({ ok: true })
      })
  
      it('should create and send a form with correct data', async () => {
        const dispute = {
          deduction: { id: '123' } as Deduction,
          message: 'Test dispute',
          dispute_amount: 100,
          files: [new File([''], 'test.pdf')],
        }
  
        await file_dispute(dispute)
  
        expect(mockFetch).toHaveBeenCalledWith('/api/dispute', expect.objectContaining({
          method: 'POST',
          credentials: 'same-origin',
        }))
  
        const calledFormData = mockFetch.mock.calls[0][1].body as FormData
        expect(calledFormData.get('deduction_id')).toBe('123')
        expect(calledFormData.get('dispute_type')).toBe('chargeback')
        expect(calledFormData.get('message')).toBe('Test dispute')
        expect(calledFormData.get('dispute_amount')).toBe('100')
        expect(calledFormData.get('files')).toBeInstanceOf(File)
      })
    })
  })
}