"use client"

import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { useEffect, useMemo, useState } from "preact/compat"
import { Button } from "@/components/ui/button"

import { DebouncedInput } from "./debounced_input"
import { Skeleton } from "@/components/ui/skeleton"
import { PageControls } from "./page_controls"
import { api_fetch } from "src/api/client.tsx"
import {
  Check,
  ChevronDown,
  CircleCheck,
  Columns2,
  Download,
  File,
  FileWarning,
  X,
} from "lucide-react"
import { useAsyncEffect } from "src/utils/util.tsx"
import { Separator } from "@/components/ui/separator"
import { StatusFlag } from "../status_state"
import {
  build_csv,
  convertToCsv,
  downloadCsv,
  filterOutInternalColumns,
  getTargetUrl,
} from "./util"
import { useLocation } from "wouter-preact"
import { AssignmentForm } from "./assignment_form"
import { UserState } from "src/auth/user.tsx"
import { User } from "@/api/user.tsx"
import { DeductionResponse, ReasonCode } from "@/api/deduction.tsx"
import { bulk_request_backup } from "@/api/dispute.tsx"
import { DAY_COUNTS } from "@/dashboard/deductions"
import { AccountingMatchPopover } from "@/accounting/match_popover"
import { DataTableState } from "@/dashboard/common"

interface DataTableProps<TData, TValue> {
  loading: boolean
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

async function downloadBackupCsv() {
  try {
    const res = await api_fetch<Record<string, number | string | boolean>[]>("/backup")
    if (!res.ok) {
      throw new Error("Failed to fetch data")
    }
    const filteredData = filterOutInternalColumns(res.value.data)
    const csv = convertToCsv(filteredData)

    downloadCsv(csv, "backup")
  } catch (error) {
    console.error("Error downloading backup CSV:", error)
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  if (value.toLowerCase() === "misc") {
    if (row.getValue("description") === "" && row.getValue("category") === "") {
      return true
    }
  }
  if (value.toLowerCase() === "new") {
    if (row.getValue("status_value") === "") {
      return true
    }
  }

  const itemValue = row.getValue(columnId)
  if (!itemValue) return false

  const itemRank = rankItem(itemValue, value)
  addMeta({ itemRank })

  return itemRank.passed
}

export function DataTable<TValue>({
  loading,
  columns,
  data,
}: DataTableProps<DeductionResponse, TValue>) {
  const [location, setLocation] = useLocation()
  const user = UserState.use()

  const tableState = DataTableState.use()

  // Destructure the values from DataTableState
  const {
    search = "",
    days: selectedDay = DAY_COUNTS[8],
    rowSelection: stateRowSelection = {},
    sorting: stateSorting = [{ id: "invoice_date", desc: true }],
    columnVisibility: stateColumnVisibility = {
      check_amount: false,
      check_number: false,
      dc_name: false,
      reason_code_type: false,
    },
    pagination: statePagination = { pageIndex: 0, pageSize: 50 },
  } = tableState
  const [rowSelection, setRowSelection] = useState(stateRowSelection)
  const [sorting, setSorting] = useState(stateSorting)
  const [columnVisibility, setColumnVisibility] = useState(stateColumnVisibility)
  const [pagination, setPagination] = useState(statePagination)
  const [requestBackupDisabled, setRequestBackupDisabled] = useState(false)
  const [assignmentPopoverOpen, setAssignmentPopoverOpen] = useState(false)
  const [users, setUsers] = useState<User[]>([])

  function handleChangeGlobalFilter(value: string) {
    DataTableState.set({
      ...tableState,
      search: value,
    })
  }

  const filteredData = useMemo(() => {
    if (search.toLowerCase() !== "validated") {
      return data.filter(deduction => deduction.status_value !== "validated")
    }
    return data
  }, [data, search])

  // sync the table-specific state with the global state
  useEffect(() => {
    DataTableState.set({
      ...tableState,
      rowSelection,
      sorting,
      columnVisibility,
      pagination,
    })
  }, [rowSelection, sorting, columnVisibility, pagination])

  const table = useMemo(() => {
    return useReactTable({
      data: filteredData,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      globalFilterFn: fuzzyFilter,
      onColumnVisibilityChange: setColumnVisibility,
      onGlobalFilterChange: handleChangeGlobalFilter,
      onRowSelectionChange: setRowSelection,
      onSortingChange: setSorting,
      onPaginationChange: setPagination,
      state: {
        columnVisibility,
        rowSelection,
        sorting,
        globalFilter: search,
        pagination,
      },
    })
  }, [data, columns, columnVisibility, search, rowSelection, sorting, pagination])

  useAsyncEffect(async () => {
    let res = await api_fetch<User[]>("/users")
    if (!res.ok) {
      console.error(`Failed to fetch users`)
      return
    }
    setUsers(res.value.data)
  }, [])

  // Handle data change to keep pagination state
  useEffect(() => {
    if (data.length < pagination.pageIndex * pagination.pageSize) {
      setPagination(prev => ({ ...prev, pageIndex: 0 }))
    }
  }, [data, pagination.pageIndex, pagination.pageSize])

  const getDownloadDataTitle = () => {
    return location.includes("deductions")
      ? "Deductions"
      : location.includes("accounting")
      ? "Accounting Codes"
      : "Invoices"
  }

  function downloadDeductionsCsv() {
    if (user?.org_id) {
      let csv = build_csv(filterOutInternalColumns(filteredData), user?.org_id)
      downloadCsv(csv, getDownloadDataTitle())
    }
  }

  const isRowSelected = Object.keys(rowSelection).length > 0

  function handleRowClick(row: any, cell_id: string) {
    const WHITELIST_ROW_ACTION_COLUMNS = [
      "invoice_number",
      "actions",
      "files",
      "select",
      "status_value",
      "category",
    ]

    if (!WHITELIST_ROW_ACTION_COLUMNS.some(action => cell_id.includes(action))) {
      if (row.original.source?.toLowerCase() === "target") {
        window.open(getTargetUrl(row), "_blank")
      } else {
        setLocation(`/deduction/${row.original.id}`)
      }
    }
  }

  async function handleTaskAssignment(values: any) {
    const selectedRows = table.getSelectedRowModel().rows
    let body = []
    for (const row of selectedRows) {
      body.push({
        deduction_id: row.original.id,
        user_id: values.user_id,
        task_type: values.taskType,
        backup_type: values.backupType,
        // due_date: values.dueDate,
      })
    }

    let res = await api_fetch("/tasks", {
      body,
    })

    if (!res.ok) {
      console.error(`Failed to assign tasks`)
    }

    // todo: toast messages...
    StatusFlag.set(flag => !flag)
    setRowSelection({})
    setAssignmentPopoverOpen(false)
  }

  async function handleSelectAccountingMatch(match: ReasonCode) {
    const selectedRows = table.getSelectedRowModel().rows
    let body = []
    for (const row of selectedRows) {
      body.push({
        deduction_id: row.original.id,
        reason_code_id: match.id,
      })
    }

    let res = await api_fetch("/reason_code_deductions", {
      body,
    })

    if (!res.ok) {
      console.error(`Failed to request backup`)
    }
    StatusFlag.set(flag => !flag)
    setRowSelection({})
    setAssignmentPopoverOpen(false)
  }

  async function bulkRequestBackup() {
    if (requestBackupDisabled) {
      return
    }
    setRequestBackupDisabled(true)
    let rows = table.getSelectedRowModel().rows
    await bulk_request_backup(rows.map(row => row.original))

    StatusFlag.set(flag => !flag)
    setRowSelection({})
    setRequestBackupDisabled(false)
  }

  async function bulkUpdateStatus(status: String) {
    let rows = table.getSelectedRowModel().rows
    let body = rows.map(row => ({
      deduction_id: row.original.id,
      status,
      notes: "bulk",
    }))

    let res = await api_fetch("/deductions/statuses/bulk", { body })

    if (!res.ok) {
      throw new Error("Failed to mark validated")
    }

    StatusFlag.set(flag => !flag)
    setRowSelection({})
  }

  const MarkNewButton = (
    <Button className="pr-2 bg-red-300-opacity-10" onClick={() => bulkUpdateStatus("new")}>
      <span className="text-red-400">Mark new</span>
      <Check className="h-4 text-red-400" />
    </Button>
  )

  return (
    <>
      <div className="flex items-center justify-between py-4 pt-8">
        <div className="flex w-full items-center space-x-4">
          <div className="w-full mr-4">
            <DebouncedInput
              value={search ?? ""}
              onChange={value => handleChangeGlobalFilter(String(value))}
              className="p-2 font-lg"
              placeholder="Search..."
              debounce={300}
            />
          </div>
        </div>
        <div class="flex justify-end mr-3">
          <Button className="px-2 text-slate-500" onClick={downloadBackupCsv} variant="outline">
            <Download className="h-4" />
            Backup
          </Button>
        </div>
        <div class="flex justify-end mr-3">
          <Button className="px-2 text-slate-500" onClick={downloadDeductionsCsv} variant="outline">
            <Download className="h-4" />
            {getDownloadDataTitle()}
          </Button>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button variant="outline" className="ml-auto text-slate-500">
              <Columns2 className="h-4" />
              Columns
              <ChevronDown className="h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter(column => column.getCanHide())
              .map(column => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value: any) => column.toggleVisibility(!!value)}>
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <Table>
        <TableHeader className="bg-slate-50">
          {table.getHeaderGroups().map((headerGroup, idx) => (
            <TableRow key={`${headerGroup.id}-${idx}`}>
              {headerGroup.headers.map((header, headerIdx) => {
                return (
                  <TableHead
                    key={`${header.id}-${headerIdx}`}
                    className="first:rounded-tl-md last:rounded-tr-md">
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        {loading ? (
          <TableBody>
            <TableRow>
              {Array.from({ length: columns.length }).map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton className="w-[100px] h-[20px] rounded-full bg-slate-500" />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, idx) => (
                <TableRow
                  key={`${row.id}-${idx}`}
                  className={
                    // @ts-ignore
                    row.original["task"].task_user_id === user?.id &&
                    row.original["task"].task_status === "pending"
                      ? `bg-plue-100`
                      : ""
                  }
                  data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell, cellIdx) => (
                    <TableCell
                      className="hover:cursor-pointer"
                      onClick={() => handleRowClick(row, cell.id)}
                      key={`${cell.id}-${cellIdx}`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {isRowSelected && (
        <div class="flex fixed bottom-10 z-10 bg-slate-900 rounded-lg border left-1/2 transform -translate-x-1/2 p-2">
          <div className="flex justify-start flex-grow">
            <Button onClick={() => setRowSelection({})} className="text-gray-400" variant="link">
              {`${table.getFilteredSelectedRowModel().rows.length} selected`}
              <X className="h-4" />
            </Button>
          </div>
          <Separator orientation="vertical" className="h-10 bg-slate-700 mr-3" />
          <div className="flex justify-end space-x-3">
            <Button
              className="pr-2 bg-orange-400-opacity-10"
              onClick={() => bulkRequestBackup()}
              disabled={requestBackupDisabled}>
              <span class="text-orange-500 ">Request backup</span>
              <File className="h-4 text-orange-500" />
            </Button>
            {table
              .getFilteredSelectedRowModel()
              .rows.every(row => row.original.status_value?.toLowerCase() === "disputable") ? (
              MarkNewButton
            ) : (
              <Button
                className="pr-2 bg-sky-400-opacity-10"
                onClick={() => bulkUpdateStatus("disputable")}>
                <span class="text-sky-500">Mark disputable</span>
                <FileWarning className="h-4 text-sky-500" />
              </Button>
            )}
            {table
              .getFilteredSelectedRowModel()
              .rows.every(row => row.original.status_value?.toLowerCase() === "validated") ? (
              MarkNewButton
            ) : (
              <Button
                className="pr-2 bg-green-500-opacity-10"
                onClick={() => bulkUpdateStatus("validated")}>
                <span className="text-green-600">Mark validated</span>
                <Check className="h-4 text-green-600" />
              </Button>
            )}

            {/* add additional popover if it's the accounting screen... */}
            {location.includes("accounting") && (
              <AccountingMatchPopover onSelectMatch={handleSelectAccountingMatch} />
            )}

            <Popover
              open={assignmentPopoverOpen}
              onOpenChange={() => setAssignmentPopoverOpen(true)}>
              <PopoverTrigger>
                {" "}
                <Button className="pr-2 bg-yellow-500-opacity-10">
                  <span class="text-yellow-600">Assign task</span>
                  <CircleCheck className="h-4 text-yellow-600" />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <AssignmentForm users={users} onSubmit={handleTaskAssignment} />
              </PopoverContent>
            </Popover>
          </div>
        </div>
      )}
      <div class="flex items-center justify-between p-2">
        {/* @ts-ignore */}
        <PageControls table={table} />
      </div>
    </>
  )
}

if (import.meta.vitest) {
  vi.mock("src/api/client", () => ({
    api_fetch: vi.fn(),
  }))

  vi.mock("./util", () => ({
    convertToCsv: vi.fn(),
    downloadCsv: vi.fn(),
    filterOutInternalColumns: vi.fn(),
    getTargetUrl: vi.fn(),
  }))

  describe("DataTable helpers and handlers", () => {
    describe("fuzzyFilter", () => {
      const mockAddMeta = vi.fn()

      it('should return true for "misc" when description and category are empty', () => {
        const row = {
          getValue: vi.fn(key => (key === "description" || key === "category" ? "" : "value")),
        }
        // @ts-ignore
        expect(fuzzyFilter(row, "columnId", "misc", mockAddMeta)).toBe(true)
      })

      it('should return true for "new" when status_value is empty', () => {
        const row = {
          getValue: vi.fn(key => (key === "status_value" ? "" : "value")),
        }
        // @ts-ignore
        expect(fuzzyFilter(row, "columnId", "new", mockAddMeta)).toBe(true)
      })

      // todo(joey): fix making sure only misc is returned when searched

      it("should return false when value does not match", () => {
        const row = {
          getValue: vi.fn(() => "different value"),
        }
        // @ts-ignore
        expect(fuzzyFilter(row, "columnId", "search term", mockAddMeta)).toBe(false)
      })
    })

    describe("downloadBackupCsv", () => {
      beforeEach(() => {
        vi.resetAllMocks()
      })

      it("should download CSV on successful fetch", async () => {
        const mockData = [{ id: 1, name: "Test" }]
        // @ts-ignore
        api_fetch.mockResolvedValue({ ok: true, value: { data: mockData } })
        vi.mocked(filterOutInternalColumns).mockReturnValue(mockData)
        vi.mocked(convertToCsv).mockReturnValue("csv data")

        await downloadBackupCsv()

        expect(api_fetch).toHaveBeenCalledWith("/backup")
        expect(filterOutInternalColumns).toHaveBeenCalledWith(mockData)
        expect(convertToCsv).toHaveBeenCalledWith(mockData)
        expect(downloadCsv).toHaveBeenCalledWith("csv data", "backup")
      })

      it("should handle fetch error", async () => {
        // @ts-ignore
        api_fetch.mockResolvedValue({ ok: false, error: new Error("Fetch failed") })
        const consoleSpy = vi.spyOn(console, "error").mockImplementation(() => {})

        await downloadBackupCsv()

        expect(consoleSpy).toHaveBeenCalledWith("Error downloading backup CSV:", expect.any(Error))
      })
    })
  })
}
