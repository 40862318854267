import { DeductionResponse } from "@/api/deduction"
import { TaskStatus, TaskType } from "@/api/task"
import { CoreInstance } from "@tanstack/react-table"

export const copyToClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Copied to clipboard:", text)
    })
    .catch(err => {
      console.error("Failed to copy: ", err)
    })
}

type AccountingHeaderMap = Record<string, Record<string, string>>
const ACCOUNTING_HEADER_MAP: AccountingHeaderMap = {
  "01j0vbrzjm2nfg26hqnqhk_8hc7": {
    invoice_amount: "UnitPrice",
    reason_code: "Name",
    reason_code_type: "Type",
    expense_account: "ExpenseAccount",
    income_account: "IncomeAccount",
  },
}

export function build_csv(filteredData: DeductionResponse[], org_id: string): string {
  const HEADER_MAP = ACCOUNTING_HEADER_MAP[org_id] || {}

  // Create header row using the HEADER_MAP or object keys
  const headers = Object.keys(filteredData[0] || {})
  const headerRow = headers.map(header => `"${HEADER_MAP[header] || header}"`).join(",")

  // Create body rows
  const body = filteredData
    .map(item => {
      return headers
        .map(header => {
          // @ts-ignore
          const cellValue = item[header]
          return `"${cellValue != null ? cellValue : ""}"`
        })
        .join(",")
    })
    .join("\n")

  // Return the complete CSV string
  return [headerRow, body].join("\n")
}

export function downloadCsv(csvData: string, filename: string) {
  const blob = new Blob([csvData], { type: "text/csv" })
  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url

  // Format the download filename with the current date
  const today = new Date()
  let year = today.getFullYear()
  let month = String(today.getMonth() + 1).padStart(2, "0")
  let day = String(today.getDate()).padStart(2, "0")
  const formattedDate = `${year}-${month}-${day}`
  a.download = `${filename}_${formattedDate}.csv`
  // Trigger the download and clean up the URL object
  a.click()
  URL.revokeObjectURL(url)
}

export function filterOutInternalColumns<T>(data: T[]) {
  const filteredData = []

  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    const filteredItem = {} as T

    for (const key in item) {
      let INTERNAL_KEYS = [
        "id",
        "created_at",
        "updated_at",
        "raw_data",
        "backup_s3_uri",
        "check_s3_uri",
        "repayment_for",
        "is_repayment",
        "is_prepayment",
        // filtering out the objects for now...
        "notes",
        "task",
        "reason_code",
      ]
      if (
        Object.prototype.hasOwnProperty.call(item, key) &&
        !key.endsWith("_id") &&
        INTERNAL_KEYS.indexOf(key) === -1
      ) {
        filteredItem[key] = item[key]
      }
    }

    filteredData.push(filteredItem)
  }

  return filteredData
}

export function convertToCsv(data: Record<string, number | string | boolean>[]) {
  if (!data || !data.length) {
    return ""
  }

  const headers = Object.keys(data[0])
  const headerRow = headers.join(",")

  const dataRows = data
    .map(item => {
      return headers
        .map(header => {
          const cellValue = item[header]
          return `"${cellValue != null ? cellValue : ""}"`
        })
        .join(",")
    })
    .join("\n")

  return [headerRow, dataRows].join("\n")
}

const TVI_PREFIXES = ["vcna", "vsup", "vcpn", "vonl", "viap"]

export function getTargetUrl(row: any): string {
  if (TVI_PREFIXES.some(prefix => row.original.invoice_number?.toLowerCase().startsWith(prefix))) {
    return `https://tvi.partnersonline.com/quick-search${row.original.invoice_number
      .split(/[-:]/)[0]
      .replace(/[^0-9]/g, "")}`
  } else if (row.original.invoice_number?.toLowerCase().startsWith("cb")) {
    return "https://apreports.partnersonline.com/credit_debit"
  } else {
    return "https://www.partnersonline.com/page/help/NDI0"
  }
}

if (import.meta.vitest) {
  describe("getTargetUrl", () => {
    it.each([
      ["VCNA0003420106-06", "https://tvi.partnersonline.com/quick-search0003420106"],
      ["VSUP0002549886-01", "https://tvi.partnersonline.com/quick-search0002549886"],
      ["VCPN0002537204-01", "https://tvi.partnersonline.com/quick-search0002537204"],
      ["VONL00253720-101", "https://tvi.partnersonline.com/quick-search00253720"],
      ["VIAP3490811", "https://tvi.partnersonline.com/quick-search3490811"],
      ["VIAP3471694:2024-06-01", "https://tvi.partnersonline.com/quick-search3471694"],
    ])("should return correct TVI URL for %s", (invoiceNumber, expectedUrl) => {
      const row = { original: { invoice_number: invoiceNumber } }
      expect(getTargetUrl(row)).toBe(expectedUrl)
    })

    it("should return credit/debit URL for CB prefix", () => {
      const row = { original: { invoice_number: "CB-123" } }
      expect(getTargetUrl(row)).toBe("https://apreports.partnersonline.com/credit_debit")
    })

    it("should return help URL for other cases", () => {
      const row = { original: { invoice_number: "OTHER-123" } }
      expect(getTargetUrl(row)).toBe("https://www.partnersonline.com/page/help/NDI0")
    })

    it("should handle lowercase invoice numbers", () => {
      const row = { original: { invoice_number: "vcna123" } }
      expect(getTargetUrl(row)).toBe("https://tvi.partnersonline.com/quick-search123")
    })

    it("should handle undefined invoice number", () => {
      const row = { original: {} }
      expect(getTargetUrl(row)).toBe("https://www.partnersonline.com/page/help/NDI0")
    })
  })

  describe("convertToCsv", () => {
    it("should convert an array of objects to CSV string", () => {
      const data = [
        { name: "John", age: 30, city: "New York" },
        { name: "Jane", age: 25, city: "Los Angeles" },
      ]
      const expected = 'name,age,city\n"John","30","New York"\n"Jane","25","Los Angeles"'
      expect(convertToCsv(data)).toBe(expected)
    })

    it("should handle empty array", () => {
      expect(convertToCsv([])).toBe("")
    })

    // our data is always given empty strings so don't need this
    // it('should handle objects with different keys', () => {
    //   const data = [
    //     { name: 'John', age: 30 },
    //     { name: 'Jane', city: 'Los Angeles' }
    //   ]
    //   const expected = 'name,age,city\n"John","30"\n"Jane",""Los Angeles"'
    //   expect(convertToCsv(data)).toBe(expected)
    // })

    it("should handle objects with boolean values", () => {
      const data = [
        { name: "John", isStudent: true },
        { name: "Jane", isStudent: false },
      ]
      const expected = 'name,isStudent\n"John","true"\n"Jane","false"'
      expect(convertToCsv(data)).toBe(expected)
    })

    it("should handle objects with special characters in values", () => {
      const data = [
        { name: 'John "Doe"', description: "Likes to use, commas" },
        { name: "Jane", description: "No special characters" },
      ]
      const expected =
        'name,description\n"John "Doe"","Likes to use, commas"\n"Jane","No special characters"'
      expect(convertToCsv(data)).toBe(expected)
    })
  })

  describe("filterOutIdColumns", () => {
    it("should filter out columns ending with _id", () => {
      const input = [
        { id: 1, user_id: 100, name: "John", age: 30 },
        { id: 2, user_id: 101, name: "Jane", age: 25 },
      ]
      const expected = [
        { name: "John", age: 30 },
        { name: "Jane", age: 25 },
      ]
      expect(filterOutInternalColumns(input)).toEqual(expected)
    })

    it("should filter out specified internal keys", () => {
      const input = [
        { id: 1, created_at: "2023-01-01", name: "John", age: 30 },
        { id: 2, updated_at: "2023-01-02", name: "Jane", age: 25 },
      ]
      const expected = [
        { name: "John", age: 30 },
        { name: "Jane", age: 25 },
      ]
      // @ts-ignore
      expect(filterOutInternalColumns(input)).toEqual(expected)
    })

    it("should handle empty input array", () => {
      expect(filterOutInternalColumns([])).toEqual([])
    })

    it("should not modify objects without filtered keys", () => {
      const input = [
        { name: "John", age: 30 },
        { name: "Jane", age: 25 },
      ]
      expect(filterOutInternalColumns(input)).toEqual(input)
    })

    it("should handle objects with only filtered keys", () => {
      const input = [
        { id: 1, user_id: 100, created_at: "2023-01-01" },
        { id: 2, user_id: 101, updated_at: "2023-01-02" },
      ]
      // @ts-ignore
      expect(filterOutInternalColumns(input)).toEqual([{}, {}])
    })

    it("should handle mixed objects with some having filtered keys and others not", () => {
      const input = [
        { id: 1, name: "John", age: 30 },
        { name: "Jane", age: 25 },
        { id: 3, user_id: 102, created_at: "2023-01-03", name: "Bob", age: 35 },
      ]
      const expected = [
        { name: "John", age: 30 },
        { name: "Jane", age: 25 },
        { name: "Bob", age: 35 },
      ]
      // @ts-ignore
      expect(filterOutInternalColumns(input)).toEqual(expected)
    })

    it("should handle objects with nested properties", () => {
      const input = [
        { id: 1, name: "John", details: { age: 30, user_id: 100 } },
        { id: 2, name: "Jane", details: { age: 25, created_at: "2023-01-01" } },
      ]
      const expected = [
        { name: "John", details: { age: 30, user_id: 100 } },
        { name: "Jane", details: { age: 25, created_at: "2023-01-01" } },
      ]
      // @ts-ignore
      expect(filterOutInternalColumns(input)).toEqual(expected)
    })

    it("should not modify original input array", () => {
      const input = [
        { id: 1, name: "John", age: 30 },
        { id: 2, name: "Jane", age: 25 },
      ]
      const originalInput = JSON.parse(JSON.stringify(input))
      filterOutInternalColumns(input)
      expect(input).toEqual(originalInput)
    })
  })

  describe("build_csv function", () => {
    const mockDeductionResponse: DeductionResponse[] = [
      {
        id: "mock-deduction-id",
        org_id: "mock-org-id",
        remote_id: "mock-remote-id",
        created_at: "2023-01-01",
        updated_at: "2023-01-02",
        source: "mock-source",
        invoice_amount: 100,
        discount_amount: 20,
        gross_amount: 80,
        invoice_date: "2023-01-03",
        invoice_number: "mock-invoice-number",
        check_amount: 80,
        check_date: "2023-01-04",
        check_number: 123,
        category_id: "mock-category-id",
        check_s3_uri: "mock-check-s3-uri",
        po_number: "mock-po-number",
        data: "mock-data",
        document: "mock-document",
        retailer_name: "mock-retailer-name",
        execution_date: "2023-01-05",
        dispute_id: "mock-dispute-id",
        category: "mock-category",
        description: "mock-description",
        status_value: "mock-status-value",
        notes: [
          {
            updated_at: "2023-01-06",
            user_id: "mock-user-id",
            message: "mock-message",
            actor_name: "mock-actor-name",
          },
        ],
        task: {
          task_id: "mock-task-id",
          task_type: TaskType.FileDispute,
          task_status: TaskStatus.Pending,
          task_user_id: "mock-task-user-id",
          task_created_at: "2023-01-07",
          task_assigner_email: "mock-task-assigner-email",
          task_user_email: "mock-task-user-email",
        },
        reason_code: {
          id: "mock-reason-code-id",
          name: "mock-reason-code-name",
          description: "mock-reason-code-description",
          expense_account: "mock-expense-account",
          actor: "mock-actor",
          code_type: "mock-code-type",
        },
      },
    ]
    // ... existing code ...
    it("should return a CSV string with default headers when org_id does not exist in HEADER_MAP", () => {
      const org_id = "non_existent_org_id" // Org ID that does not exist in HEADER_MAP
      const result = build_csv(filterOutInternalColumns(mockDeductionResponse), org_id)

      // Expected header row (using column IDs since HEADER_MAP does not contain the org_id)
      const expectedHeaderRow =
        '"source","invoice_amount","discount_amount","gross_amount","invoice_date","invoice_number","check_amount","check_date","check_number","po_number","data","document","retailer_name","execution_date","category","description","status_value"'
      const expectedBodyRow =
        '"mock-source","100","20","80","2023-01-03","mock-invoice-number","80","2023-01-04","123","mock-po-number","mock-data","mock-document","mock-retailer-name","2023-01-05","mock-category","mock-description","mock-status-value"'
      const expectedCsv = `${expectedHeaderRow}\n${expectedBodyRow}`

      expect(result).toBe(expectedCsv)
    })

    it("should return a CSV string with mapped headers when org_id exists in HEADER_MAP", () => {
      const org_id = "01j0vbrzjm2nfg26hqnqhk_8hc7" // Valid org_id
      const result = build_csv(filterOutInternalColumns(mockDeductionResponse), org_id)

      // Expected header row using the mappings from HEADER_MAP
      const expectedHeaderRow =
        '"source","UnitPrice","discount_amount","gross_amount","invoice_date","invoice_number","check_amount","check_date","check_number","po_number","data","document","retailer_name","execution_date","category","description","status_value"'
      const expectedBodyRow =
        '"mock-source","100","20","80","2023-01-03","mock-invoice-number","80","2023-01-04","123","mock-po-number","mock-data","mock-document","mock-retailer-name","2023-01-05","mock-category","mock-description","mock-status-value"'
      const expectedCsv = `${expectedHeaderRow}\n${expectedBodyRow}`

      expect(result).toBe(expectedCsv)
    })
  })
}
