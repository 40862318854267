import { useState } from "preact/compat"
import { useAsyncEffect } from "../../utils/util.tsx"
import { api_fetch } from "../../api/client.tsx"
import { Deduction, DeductionResponse } from "../../api/deduction.tsx"
import { SummaryCards } from "./summary_cards.tsx"
import { GenericFilter } from "../component/filter.tsx"
import { COLUMNS } from "./table/columns.tsx"
import { DataTable } from "./table/data_table.tsx"
import { StatusFlag } from "./status_state"
import { BreadcrumbNav, DataTableState } from "../common.tsx"

export const DAY_COUNTS = [7, 14, 21, 30, 60, 90, 120, 150, 180, 365, 500]
export const DISTRIBUTORS = ["All Distributors", "KeHE", "UNFI", "Target"]

export function dayCountToString(item: number) {
  if (item > 365) {
    return "All time"
  } else if (item === 365) {
    return "Past year"
  } else if (item < 365 && item > 30) {
    return `Past ${item / 30} months`
  } else {
    return `Past ${item} days`
  }
}

export interface Params {
  search?: string
  days?: number
  distributor?: string
}

export function Deductions() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<DeductionResponse[]>([])
  const statusFlag = StatusFlag.use(sf => sf!)
  const tableState = DataTableState.use()

  const {
    days: selectedDay = DAY_COUNTS[8],
    distributor: selectedDistributor = DISTRIBUTORS[0],
  } = tableState

  function handleDayCountChange(days: number) {
    DataTableState.set({ ...tableState, days })
  }

  function handleDistributorChange(distributor: string) {
    DataTableState.set({ ...tableState, distributor })
  }

  useAsyncEffect(async () => {
    let distributor = selectedDistributor === "All Distributors" ? "" : selectedDistributor
    const res = await api_fetch<Deduction[]>("/deduction", { params: { days: selectedDay, distributor } })
    setLoading(false)
    if (!res.ok) {
      throw new Error("Failed to fetch data")
    }

    // convert null values to empty strings to ensure filtering works as expected
    const data = res.value.data.map(d => {
      return Object.fromEntries(Object.entries(d).map(([k, v]) => [k, v ?? ""])) as DeductionResponse
    })
    setData(data)
  }, [selectedDay, statusFlag, selectedDistributor])

  return (
    <div>
      <BreadcrumbNav items={[{ label: "Deductions", href: "/deductions" }]} />
      <GenericFilter
        items={DISTRIBUTORS}
        selectedItem={selectedDistributor}
        handleOnChange={handleDistributorChange}
        itemToString={(item) => item}
        className="absolute right-44 top-0 mt-6 w-36 mr-8"
      />
      <GenericFilter
        items={DAY_COUNTS}
        selectedItem={selectedDay}
        handleOnChange={handleDayCountChange}
        itemToString={dayCountToString}
        className="absolute right-8 top-0 mt-6 w-40 max-w-sm"
      />
      <SummaryCards selectedDay={selectedDay} selectedDistributor={selectedDistributor} />
      <div className="mx-auto">
        <DataTable loading={loading} columns={COLUMNS} data={data} />
      </div>
    </div>
  )
}
